<template>
  <div class="home">
    <div class="home-banner">
      <div class="home-banner-content">
        <el-carousel
          ref="carousel"
          trigger="click"
          height="400px"
          arrow="never">
          <el-carousel-item
            v-for="(item, index) in bannerData"
            :key="index"
          >
            <div class="el-carousel-item_container"
                 :style="`background-color:${item.bgColor};`"
            >
              <div
                :class="`el-carousel-item_content ${item.className}`"
                v-if="item.url"
                :style="`background: url(${item.url}) no-repeat center center ;background-size: cover;`| isWebp(item.url)"
              >
                <GImage
                  :style="item.iconStyle"
                  v-if="index === 0"
                  g-src="page/ywqnHomePage/views/index/home/banner/banner1-icon.png"
                ></GImage>
              </div>
              <div class="el-carousel-item_content" v-else></div>
            </div>
          </el-carousel-item>
          <div
            @click="$refs.carousel.prev()"
            class="prev-btn">
            <img src="./banner/icon-arrows.png"/>
          </div>
          <div
            @click="$refs.carousel.next()"
            class="next-btn">
            <img src="./banner/icon-arrows.png"/>
          </div>
        </el-carousel>
      </div>
    </div>
    <div class="section-business">
      <sectionTitle>
        <template #primary>业务范围</template>
      </sectionTitle>
      <div class="section-business-container">
        <div class="section-business-content">
          <router-link
            :to="{path: item.path}"
            tag="div"
            class="item"
            v-for="(item, index) in sectionBusiness"
            :key="index"
          >
            <div class="item-hd">
              {{item.title}}
            </div>
            <div class="item-bd">
              {{item.content}}
              <div class="item-icon"></div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="section-certification">
      <sectionTitle>
        <template #primary>资质证书</template>
      </sectionTitle>
      <div class="section-certification-container">
        <div class="section-certification-content">
          <div class="section-certification-text">
            税局委托代征书 + 营业执照 + 人力资源服务许可证
          </div>
          <div class="section-certification-img">
            <g-image g-src="page/ywqnHomePage/views/index/home/img/icon-certification.png"></g-image>
          </div>
        </div>
      </div>
    </div>
    <div class="section-server">
      <sectionTitle color="white" line-color="br666">
        <template #primary>服务标准</template>
      </sectionTitle>
      <div class="section-server-container">
        <div class="section-server-content">
          <div
            class="server-row"
            v-for="(item, index) in serverStandard"
            :key="index"
          >
            <div
              v-for="(ch_item, index) in item"
              :key="index"
              class="server-item"
            >
              <div class="server-item-img">
                <g-image :g-src="ch_item.icon"></g-image>
              </div>
              <div class="server-item-name">{{ch_item.name}}</div>
              <div class="server-item-text">{{ch_item.text}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-partner">
      <sectionTitle>
        <template #primary>部分合作伙伴</template>
      </sectionTitle>
      <div class="section-partner-container">
        <div class="section-partner-content">
          <div class="section-partner-img">
            <g-image g-src="page/ywqnHomePage/views/index/home/img/icon-partner.png"></g-image>
          </div>
        </div>
      </div>
    </div>
    <div class="section-ywgz">
      <sectionTitle>
        <template #primary>他们眼中的有为</template>
      </sectionTitle>
      <div class="section-ywgz-container">
        <div class="section-ywgz-content">
          <el-carousel
            ref="carousel"
            trigger="click"
            height="530px"
            arrow="never">
            <el-carousel-item
              v-for="(item, index) in ywgzStaff"
              :key="index"
            >
              <div class="section-ywgz-carousel">
                <div class="carousel-left">
                  <g-image :g-src="item.banner"></g-image>
                </div>
                <div class="carousel-right">
                  <div class="carousel-right-hd">
                    <div class="info">
                        <div class="head">
                          <g-image :g-src="item.headIcon"></g-image>
                        </div>
                        <div class="name">{{item.name}}</div>
                    </div>
                    <div class="star-content">
                      <div class="icon-star"></div>
                      <div class="icon-star"></div>
                      <div class="icon-star"></div>
                      <div class="icon-star"></div>
                      <div class="icon-star"></div>
                    </div>
                  </div>
                  <div class="carousel-right-bd">
                    {{item.content}}
                  </div>
                  <div class="carousel-right-ft">
                    <div class="user-img">
                      <g-image :g-src="item.companyLogo"></g-image>
                    </div>
                    <div
                      class="user-tag"
                      v-for="tag in item.tag"
                      :key="tag">{{tag}}</div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from './../components/sectionTitle/sectionTitle'
export default {
  name: 'home',
  components: {
    sectionTitle
  },
  data () {
    return {
      bannerData: [
        {
          url: require('./banner/banner1.png'),
          icon: require('./banner/banner1-icon.png'),
          iconStyle: 'width: 1078px; height: 168px',
          className: 'banner1',
          href: '',
          bgColor: '#27282C'
        }
      ],
      sectionBusiness: [
        {
          title: '有为灵工',
          content: '针对大中型客户的实际业务场景，定制输出灵活用工解决方案，协助客户实现多样化用工模式转型。',
          path: '/employment'
        },
        {
          title: '灵用通',
          content: '面向个人护理用品线下零售行业。 专为零售行业定制研发的灵活用工产品，贴合解决零售行业用工难题。',
          path: '/lingyongtongPage'
        },
        {
          title: '优职招聘',
          content: '专注服务业蓝领群体的招工平台。 利用AI和大数据，智能匹配企业空缺岗位和求职者，提升招聘效率。',
          path: '/youzhiRecruit'
        },
        {
          title: '有为云',
          content: '包含但不限于劳务派遣、人力资源外包。提供人员管理、薪酬代发、电子签约等服务，降低企业用工成本。',
          path: '/youweiCloud'
        },
        {
          title: '灵保宝',
          content: '与国内外各大保险公司合作，满足企业及员工各类保险需求。提供保险咨询服务，帮助客户公正处理索赔。',
          path: '/insurance'
        },
        {
          title: '政府园区',
          content: '与地方政府合作，获取大量优惠的招商引资政策。为地方政府与企业搭建合作沟通的便捷平台。',
          path: '/parkOperation'
        }
      ],
      serverStandard: [
        [
          {
            icon: 'page/ywqnHomePage/views/index/home/img/icon-server-1.png',
            name: '便捷',
            text: '人事专线 驻场答疑'
          },
          {
            icon: 'page/ywqnHomePage/views/index/home/img/icon-server-2.png',
            name: '专业',
            text: '人事管理 项目总结'
          },
          {
            icon: 'page/ywqnHomePage/views/index/home/img/icon-server-3.png',
            name: '快速',
            text: '需求扩大 人员补充'
          },
          {
            icon: 'page/ywqnHomePage/views/index/home/img/icon-server-4.png',
            name: '完整',
            text: '员工培训 人员增值'
          }
        ],
        [
          {
            icon: 'page/ywqnHomePage/views/index/home/img/icon-server-5.png',
            name: '规范',
            text: '制度规范 准确传递'
          },
          {
            icon: 'page/ywqnHomePage/views/index/home/img/icon-server-6.png',
            name: '安心',
            text: '风险管控 应急预案'
          },
          {
            icon: 'page/ywqnHomePage/views/index/home/img/icon-server-7.png',
            name: '贴心',
            text: '心理疏导 人员稳定'
          }
        ]
      ],
      ywgzStaff: [
        {
          banner: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-banner-1.png',
          headIcon: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-head-1.png',
          name: 'HR主管',
          content: '有为工作是一个努力而年轻的团队，他们造就了有为工作这个安全、靠谱、负责的品牌。灵活用工业务选择有为工作不会错，合规性极好、服务到位、让我们非常省心！',
          companyLogo: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-item-1.png',
          tag: ['有为灵工', '优职招聘']
        },
        {
          banner: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-banner-2.png',
          headIcon: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-head-2.png',
          name: '财务经理',
          content: '这是一个稳定、可靠、准时、专业的平台，和有为工作合作一年，效果非常不错！',
          companyLogo: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-item-2.png',
          tag: ['有为灵工', '灵用通']
        },
        {
          banner: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-banner-3.png',
          headIcon: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-head-3.png',
          name: '招聘经理',
          content: '工作人员基本能力好，候选人员范围广、素质高，有为工作人员对于问题反馈、服务都非常及时；期待有为工作后续能为企业与劳动者都提供良好的保障。',
          companyLogo: 'page/ywqnHomePage/views/index/home/img/icon-ywqn-item-3.png',
          tag: ['有为灵工']
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="less">
  .home {
    .home-banner {
      .el-carousel {
        min-width: 1200px;
        overflow: hidden;
      }
      .home-banner-content{
        .el-carousel__indicators{
          bottom: 36px;
        }
      }
      .el-carousel__indicator{
        padding: 0 15px;
        &.is-active .el-carousel__button{
          background-color: @primary-color;
        }
        .el-carousel__button{
          width: 32px;
          height: 2px;
          opacity: 1;
          background-color: #C0C4CC;
        }
      }
    }
    .section-ywgz{
      .el-carousel__indicator{
        padding: 0 15px;
        &.is-active .el-carousel__button{
          background-color: @primary-color;
        }
        .el-carousel__button{
          width: 10px;
          height: 10px;
          border-radius: 100%;
          opacity: 1;
          background-color: #E4E7ED;
        }
      }
    }
  }
</style>

<style scoped lang="less">
  @import "home";
</style>

<style scoped>
  .section-ywgz .carousel-left {
    overflow: hidden;
    position: relative;
    animation: transform 25s ease-in-out infinite alternate both;
    transform-style: preserve-3d;
  }
  @keyframes transform {
    0%, 100% {
      border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
    }
    14% {
      border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
    }
    28% {
      border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
    }
    42% {
      border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
    }
    56% {
      border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
    }
    70% {
      border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
    }
    84% {
      border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    }

  }
</style>
